.footer {
  margin-top: 7rem;
  margin-bottom: 4rem;
  @media screen and (max-width: 768px) {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
  .social-media {
    display: flex;
    max-width: 187px;
    margin: 0 auto;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        max-width: 134px;
    }
    .single-media {
      .media-item {
        color: #000;
        background: #fff00a;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
          width: 35px;
          height: 35px;
        }
        i {
          font-size: 2rem;
          @media screen and (max-width: 768px) {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .copyright {
    color: #d9d6d6;
    padding-top: 1rem;
    max-width: 566px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    @media screen and (max-width: 768px) {
        font-size: 11px;
    }
  }
}
