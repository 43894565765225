.button-man {
  .container {
    position: relative;
    z-index: 6;
  }
  .main-header {
    position: relative;
    .main-banner {
      // width: 100%;
      .gradent {
        background: linear-gradient(180deg, black 0%, rgba(51, 51, 56, 0) 100%);
        position: absolute;
        height: 11%;
        width: 100%;
      }
      .img-responsive {
        //   height: 100vh;
        //   object-fit: cover;
        object-fit: cover;
        width: 100%;
        // height: 100vh;
      }
    }
    .header {
      position: absolute;
      top: 2rem;
      width: 100%;
      left: 0;
      @media screen and (max-width: 768px) {
        top: 0;
      }
      .responsive-menu {
        @media screen and (max-width: 768px) {
          position: relative;
          left: 19rem;
          border: unset;
          .navbar-toggler-icon {
            background-image: url("../../../public/icons/menu.png");
          }
          &:focus {
            box-shadow: unset;
          }
        }
      }
      .nav-bar {
        @media screen and (max-width: 768px) {
          margin-left: -0.8rem;
          background: #2d2d2d;
          padding-left: 1.5rem;
          margin-top: 0.5rem;
        }
        .navbar-nav {
          margin: 0 4rem;
          @media screen and (max-width: 768px) {
            margin: 0;
          }
        }
        .nav-link {
          color: #fff !important;
          font-size: 1.2rem;
          // padding-right: 2rem !important;
          z-index: 1;
          @media screen and (max-width: 768px) {
            padding: 1rem !important;
            font-size: 0.9rem;
          }
        }
      }
      .logo {
        // z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        top: -6px;
        display: flex;
        justify-content: center;
        // margin: 0 auto;
        @media screen and (max-width: 768px) {
          justify-content: flex-start;
          top: 5px;
          left: 1.3rem;
        }
        .img-responsive {
          color: #fff;
          // font-size: 2rem;
          img {
            width: 22rem;
            @media screen and (max-width: 768px) {
              width: 10rem;
            }
          }
        }
      }
    }
    .we-shoot-for-money {
      position: absolute;
      bottom: 18%;
      left: 17.4%;
      width: 55vw;
      @media screen and (max-width: 768px) {
        bottom: 18.1%;
        left: 17.5%;
      }
      img {
        width: 100%;
      }
    }
  }
  .are-you-ready {
    background: #131516;
    clip-path: polygon(0 14%, 100% 0, 100% 100%, 0% 100%);
    padding-top: 8rem;
    margin-top: -13.5rem;
    @media screen and (max-width: 768px) {
      clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
      padding-top: 3rem;
      margin-top: -3rem;
    }
    .ready-heading {
      float: right;
    }
    .get-client-to-sign {
      padding-top: 6rem;
      text-align: center;
      color: #fff;
      padding-bottom: 1rem;
      font-size: 1.1rem;
      @media screen and (max-width: 768px) {
        padding-top: 3.4rem;
        font-size: 0.9rem;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    .join-us {
      display: flex;
      justify-content: center;
      padding-bottom: 6rem;
      @media screen and (max-width: 768px) {
        padding-bottom: 3rem;
      }
      .join-us-btn {
        padding: 4px 27px;
        font-size: 1.6rem;
        text-decoration: none;
        color: #ffef48;
        position: relative;
        letter-spacing: 2px;
        @media screen and (max-width: 768px) {
          font-size: 1.2rem;
          padding: 9px 17px;
        }
        span {
          transform: skew(-10deg);
          border: 3px solid #ffef48;
          position: absolute;
          width: 100%;
          height: 100%;
          right: 0;
          top: 0;
        }
      }
    }
    .project-heading {
      margin-bottom: -13rem;
      @media screen and (max-width: 768px) {
        margin-bottom: 0;
        text-align: center;
      }
    }
    .project-background {
      position: relative;
      padding-top: 7rem;
      @media screen and (max-width: 768px) {
        padding-top: 0;
      }
      .camera-banner {
        .camera-img {
          width: 100%;
          // height: 70vh;
          // object-fit: cover;
        }
      }
      .project-list {
        display: flex;
        justify-content: space-between;
        margin-top: -9rem;
        @media screen and (max-width: 768px) {
          margin-top: 0;
          display: block;
        }
        .single-list {
          width: 24%;
          @media screen and (max-width: 768px) {
            width: 70%;
            margin: 0 auto;
            margin-top: 2rem;
          }
          .heading {
            color: #fff;
            text-align: center;
            font-weight: 500;
            font-size: 1.5rem;
            margin-bottom: 0;
            @media screen and (max-width: 768px) {
              font-size: 1.2rem;
            }
          }
          .single-list-content {
            text-align: center;
            color: #fff;
            padding-top: 1.5rem;
            @media screen and (max-width: 768px) {
              font-size: 0.9rem;
              padding-top: 0.8rem;
            }
          }
          img {
            width: 100%;
          }
        }
      }
      // .road-map {
      //   // position: absolute;
      //   // bottom: -3rem;
      //   img {
      //     width: 75%;
      //     margin: 0 auto;
      //     display: flex;
      //   }
      // }
    }
  }
  .road-map {
    margin-top: 5rem;
    @media screen and (max-width: 768px) {
      margin-top: 3rem;
    }
    .gradient {
      background: linear-gradient(180deg, #131516 0%, rgba(51, 51, 56, 0) 100%);
      position: absolute;
      height: 34%;
      width: 100%;
      @media screen and (max-width: 768px) {
        height: 17%;
      }
    }
    .road-map-heading {
      text-align: center;
      // margin-top: 5rem;
      margin-bottom: -7rem;
      @media screen and (max-width: 768px) {
        // margin-top: 3rem;
        margin-bottom: -3rem;
      }
    }
    img {
      width: 100%;
    }
  }
  .partners {
    margin-top: 3rem;
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
    }
    .partner-heading {
      text-align: center;
    }
    .partners-list {
      max-width: 950px;
      margin: 0 auto;
      .partners-signle-list {
        margin-top: 3rem;
        @media screen and (max-width: 768px) {
          margin-top: 1.5rem;
        }
        img {
          height: 36px;
          display: flex;
          margin: 0 auto;
          @media screen and (max-width: 768px) {
            height: 18px;
          }
        }
      }
    }
  }
}

// .circular {
//   font-family: "CircularStd";
// }
// @font-face {
//   font-family: Epsum;
//   src: url('/font/Epsum-Black-Italic.otf');
// }
