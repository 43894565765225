.comming-soon {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding-top: 2rem;
  @media screen and (max-width: 768px) {
    min-height: unset;
  }
  .particle {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
  }
  .buttonman-club {
    text-align: center;
    margin-bottom: 1rem;
    @media screen and (max-width: 768px) {
      margin-bottom: 1.5rem;
      margin-top: 3rem;
      font-size: 2rem;
    }
  }
  .coming-soon-heading {
    text-align: center;
    padding-top: 4rem;
    //   animation-name: moveInleft;
    //   animation-duration: 2s;
    // background: linear-gradient(
    //   to left,
    //   violet,
    //   indigo,
    //   #d0d0f2,
    //   green,
    //   yellow,
    //   orange,
    //   red
    // );
    //   -webkit-background-clip: text;
    // color: transparent;

    //   @keyframes moveInleft {
    //     0% {
    //       opacity: 0;
    //       transform: translateX(-1000px);
    //     }

    //     80% {
    //       transform: translateX(100px);
    //     }
    //     100% {
    //       opacity: 1;
    //       transform: translate(10);
    //     }
    //   }
    @media screen and (max-width: 768px) {
      padding-top: 1rem;
    }
  }
  .we-shoot-people {
    color: #fff;
    text-align: center;
    padding-bottom: 2rem;
    font-size: 1.4rem;
    //   animation-duration: 2s;
    //   animation-name: moveInleft;
    //   -webkit-background-clip: text;

    @media screen and (max-width: 768px) {
      font-size: 1.03rem;
    }
  }
  .coming-soon-list {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    // height: 26vw;
    @media screen and (max-width: 768px) {
      display: block;
    }

    .single-list {
      position: relative;
      flex: 0 0 27%;
      @media screen and (max-width: 768px) {
        margin-bottom: 5rem;
      }
      .heading {
        color: #fff;
        text-align: center;
        font-weight: 500;
        font-size: 1rem;
        padding-top: 1rem;
        // position: absolute;
        // left: 0;
        // right: 0;

        @media screen and (max-width: 768px) {
          // padding-top: 0.8rem;
          font-size: 1rem;
        }
      }
      .card-content {
        color: #fff;
        // top: 5rem;
        // position: absolute;
        // left: 0;
        // right: 0;
        // text-align: center;
        // bottom: 0;
        @media screen and (max-width: 768px) {
          // text-align: center;
          padding: 0 1.5rem;
        }
        p {
          margin-bottom: 5px;
          font-size: 0.8rem;
          position: relative;
          padding-left: 5px;
          &::before {
            content: "";
            position: absolute;
            left: -9px;
            top: 8px;
            width: 5px;
            height: 5px;
            background-color: #fff00a;
            border-radius: 50%;
            opacity: 0.8;
          }
        }
      }
      .single-card {
        position: relative;
        @media screen and (max-width: 768px) {
          width: 80%;
          margin: 0 auto;
        }

        &:hover {
          .card-frame {
            transform: scale(1.17, 1.0999);
            opacity: 1;
          }
          // .card-1 {
          //   transform: rotateY(0.5turn);
          // }
          // .heading {
          //   top: 0%;
          //   color: #fff00a;
          // }
          .frame-img {
            transform: scale(1.09, 1.06);
          }
        }
        .card-frame {
          position: absolute;
          width: 100%;
          transform: scale(1.07, 1.04);
          height: 99%;
          opacity: 0.8;
          transition: transform 0.2s cubic-bezier(0.17, 0.67, 0, 1.76),
            opacity 0.2s cubic-bezier(0.17, 0.67, 0, 1.76);

          @media screen and (max-width: 768px) {
            // width: 100%;
            // height: 83%;
          }
        }

        .card-1 {
          position: relative;
          // cursor: pointer;
          transition: 1s ease-in-out;
          transform-style: preserve-3d;

          .card-face {
            position: relative;
            backface-visibility: hidden;
            transition: 1s ease-in-out;
            .frame-img {
              width: 100%;
              transition-delay: 0.1s;
              transition: transform 0.2s cubic-bezier(0.17, 0.67, 0, 1.76);
            }
            .blur-img {
              filter: blur(1.2px);
            }
            &-back {
              position: absolute;
              transform: rotateY(0.5turn);

              // transform: rotateY(180deg);
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
            }
          }
        }
      }
    }
  }
  .buttonman-footer {
    text-align: center;
    color: #fff;
    font-size: 14px;
    padding-top: 2rem;
    padding-bottom: 1.4rem;
    @media screen and (max-width: 768px) {
      font-size: 11px;
      margin-bottom: 1rem;
    }
  }
}
