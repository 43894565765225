// @import '/styles/variables.scss';

.container {
  background-color: #000;
  &.jobApplication {
    margin-bottom: 0;
    .subHeading {
      text-transform: uppercase;
    }
    .content {
      padding-top: 0;
    }
  }

  .content {
    // padding-top: 120px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    font-style: normal;
    text-align: center;
  }
  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: 30px;
    text-align: center;
  }
  .label {
    color: #fff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
  }
  form {
    padding-top: 40px;
    @media screen and (max-width: 768px) {
      padding-top: 22px;
    }
  }
  input,
  textarea,
  .fileUpload {
    background-color: #4b4b4b !important;
    display: block;
    // width: 400px;
    border: 1px solid #4b4b4b;
    border-radius: 6px;
    min-height: 45px;
    color: #fff;
    @media screen and (max-width: 768px) {
      min-height: 40px;
    }
  }
  .fileUpload {
    position: relative;
    z-index: 0;
    > div {
      &:first-child {
        width: 100%;
        height: 100%;
        display: block;
        height: 45px;
        span {
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }
    }
    &.hasFile {
      &::after {
        display: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      // background-image: url('/images/upload.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      right: 5px;
      top: 19%;
      z-index: -1;
    }
  }
  input:focus,
  textarea:focus {
    color: #fff;
  }
  input,
  select,
  textarea {
    color: #fff;
  }
  .fromButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    // height: 46px;
    // border: none;
    // background: #fff;
    // border-radius: 6px;
    // padding: 8px 54px;
    // text-transform: unset;
    // color: #000 !important;
    padding: 0 2rem 2.4rem 2rem;
    font-size: 1.6rem;
    color: #ffef48;
    letter-spacing: 2px;
    background: transparent;
	transform: skew(-10deg);
    border: 3px solid #ffef48;
    height: 46px;
	margin-top: 1rem;
	@media screen and (max-width: 768px) {
		padding: 0px 1rem 1.7rem 1rem;
	}
  }
  .btn span {
    font-size: 1.1rem;
    line-height: 30px;
    font-weight: 600;
    font-style: normal;
  }
}
