.container {
  width: 30rem;
  height: 30rem;
  /* background-color: red; */
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
  @media screen and (max-width: 767px) {
    width: 100vw;
    height: 100vw;
  }
}
